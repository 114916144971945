












































import {Component, Prop, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import VueSelect from "vue-select";
import axios from "axios";

@Component({
  components: {
    TextInput,
    FormButton,
    VueSelect,
    CheckboxInput,
  },
  props: ["user", "expanded", "rights"],
})
export default class ControlPanelUser extends Vue {
  @Prop()
  public user: any;

  @Prop()
  public rights: any[];

  // public rightsChecked = this.rights.map({checked: })

  public editName = this.user.name || "";
  public editNameWarning = false;

  public editRole = this.user.role_id;

  public editEmail = this.user.email;
  public editEmailWarning = false;

  public editInstitute = this.user.institute || "";
  public editInstituteWarning = false;

  public editCity = this.user.city || "";
  public editCityWarning = false;

  public editCountry = this.user.country || "";
  public editCountryWarning = false;

  public editRights: any[] = [];

  @Prop()
  public expanded: boolean;

  public rightsExpanded = false;

  public created() {
    this.user.rights.forEach((right: any) => {
      this.editRights.push(right);
    });
  }

  public validate() {
    this.editNameWarning = !this.editName.trim();
    this.editEmailWarning = !emailRegex.test(this.editEmail);
    this.editCityWarning = !this.editCity.trim();
    this.editCountryWarning = !this.editCountry.trim();
    this.editInstituteWarning = !this.editInstitute.trim();

    return !(this.editNameWarning || this.editEmailWarning ||
      this.editCityWarning || this.editCountryWarning || this.editInstituteWarning);
  }

  public async saveUser() {
    const changedUser = {
      name: this.editName,
      email: this.editEmail,
      institute: this.editInstitute,
      city: this.editCity,
      country: this.editCountry,
    };

    if (this.validate()) {
      await axios.patch("/controlpanel/users/" + this.user.id, changedUser);

      const updates = this.rights.map((right) => {
        const inOldRights = !!this.user.rights.find((r: any) => r.id == right.id);
        const inNewRights = !!this.editRights.find((r: any) => r.id == right.id);
        if (inOldRights && !inNewRights) {
          return axios.delete(`/controlpanel/users/${this.user.id}/rights/${right.id}`);
        }
        if (!inOldRights && inNewRights) {
          return axios.post(`/controlpanel/users/${this.user.id}/rights`, right);
        }

        return Promise.resolve();
      });

      Promise.all(updates as Array<Promise<void>>).then(() => {
        this.toggleExpand();
        this.$emit("edit", {
        user: {rights: this.editRights, ...changedUser},
        id: this.user.id,
      });
      });
    }
  }

  public deleteUser() {
    if (confirm("Are you sure you want to delete this user?")) {
      axios.delete("/controlpanel/users/" + this.user.id).then(() => {
        this.toggleExpand();
        this.$emit("delete", this.user.id);
      });
    }
  }

  public toggleExpand() {
    this.$emit("toggleExpand", this.user.id);
  }

  public toggleRights() {
    this.rightsExpanded = !this.rightsExpanded;
  }

  public editRight(right: any) {
    const index = this.editRights.findIndex((r: any) => r.id == right.id);

    if (index < 0) {
      this.editRights.push(right);
    } else {
      this.editRights.splice(index, 1);
    }
  }

  public activateUser() {
    axios.patch("/controlpanel/users/" + this.user.id, {
      active: true,
    }).then(() => {
      this.$emit("activateEmail", this.user.id);
    });
  }
}
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
