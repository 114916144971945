







































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import ControlPanelUser from "@/components/control-panel/users/ControlPanelUser.vue";
import SearchBar from "@/components/control-panel/users/SearchBar.vue";
import PageControls from "@/components/control-panel/users/PageControls.vue";
import FormButton from "@/components/form/Button.vue";
import TextInput from "@/components/form/TextInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";

@Component({
  components: {
    ControlPanelUser,
    SearchBar,
    PageControls,
    FormButton,
    TextInput,
    CheckboxInput,
  },
})
export default class UsersControlPanel extends Vue {
  public users: any[] = [];
  public rights: any[] = [];

  public currentPage: number = 1;
  public totalPages: number = 1;
  public searchQuery = "";
  public expandedId = -1;

  public newName = "";
  public newEmail = "";
  public newInstitute = "";
  public newCity = "";
  public newCountry = "";
  public newUserRights: any[] = [];

  public newNameWarning = false;
  public newEmailWarning = false;
  public newInstituteWarning = false;
  public newCityWarning = false;
  public newCountryWarning = false;
  public newUserRightsWarning = false;

  public created() {
    this.getUsers();
    axios.get("/controlpanel/users/rights").then((res) => {
      this.rights = res.data;
    });
  }

  public search(query: string) {
    this.searchQuery = query;
    this.currentPage = 1;
    this.getUsers();
  }

  public clearSearch() {
    this.searchQuery = "";
    this.currentPage = 1;
    this.getUsers();
  }

  public forward() {
    this.currentPage++;
    this.getUsers();
  }

  public back() {
    this.currentPage--;
    this.getUsers();
  }

  public isExpanded(id: number) {
    return this.expandedId == id;
  }

  public toggleExpand(id: number) {
    if (id == this.expandedId) {
      this.expandedId = -1;
    } else {
      this.expandedId = id;
    }
  }

  public editUser({ user, id, role }: any) {
    const original = this.users.find((u) => u.id == id);
    original.name = user.name;
    original.role = role;
    original.email = user.email;
    original.institute = user.institute;
    original.city = user.city;
    original.country = user.country;
    original.rights = [];
    user.rights.forEach((right: any) => {
      original.rights.push(right);
    });
  }

  public activateEmail(id: number) {
    const original = this.users.find((u) => u.id == id);
    original.active = true;
  }

  public deleteUser(id: number) {
    this.users.splice(
      this.users.findIndex((u) => u.id == id),
      1,
    );
  }

  public getUsers() {
    axios
      .get("/controlpanel/users", {
        params: { q: this.searchQuery, page: this.currentPage},
      })
      .then((res) => {
        this.currentPage = +res.data.pagination.current_page;
        this.totalPages = +res.data.pagination.total_pages;
        this.users = res.data.data;
      });
  }

  public editRight(right: any) {
    const index = this.newUserRights.findIndex((r: any) => r.id == right.id);

    if (index < 0) {
      this.newUserRights.push(right);
    } else {
      this.newUserRights.splice(index, 1);
    }
  }

  public validate() {
    this.newNameWarning = !this.newName.trim();
    this.newEmailWarning = !emailRegex.test(this.newEmail);
    this.newCityWarning = !this.newCity.trim();
    this.newCountryWarning = !this.newCountry.trim();
    this.newInstituteWarning = !this.newInstitute.trim();
    this.newUserRightsWarning = this.newUserRights.length < 1;

    return !(this.newNameWarning || this.newEmailWarning ||
      this.newCityWarning || this.newCountryWarning || this.newInstituteWarning);
  }

  public addUser() {
    if (this.validate()) {
      const user = {
        name: this.newName,
        email: this.newEmail,
        city: this.newCity,
        country: this.newCountry,
        institute: this.newInstitute,
        rights: this.newUserRights.map((right) => right.id),
      };
      axios.post("/controlpanel/users", user).then((res) => {
        this.$modal.hide("add-user");
        this.newName = "";
        this.newEmail = "";
        this.newCity = "";
        this.newCountry = "";
        this.newInstitute = "";
        this.newUserRights = [];

        this.users.push(res.data);
        this.users.sort((a: any, b: any) => a.name.localeCompare(b.name));
      })
      .catch((res) => {
        if (res.response && res.response.status == 409) {
          alert("User with this email address already exists");
        }
      });
    }
  }
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
