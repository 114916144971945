










































import {Component, Prop, Vue} from "vue-property-decorator";
import ControlPanelDownloadList from "../../components/control-panel/downloads/ControlPanelDownloadList.vue";
import TextInput from "../../components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import VueSelect from "vue-select";
import axios from "axios";

@Component({
  components: {
    ControlPanelDownloadList,
    TextInput,
    FormButton,
    VueSelect,
  },
})
export default class DownloadsControlPanel extends Vue {
  @Prop({required: true, type: String})
  public title: string;

  public protocolName: string = "";
  public protocolNameWarning = false;
  public fileInputWarning = false;

  public editDownloadName: string = "";
  public currentEditId = -1;
  public editProtocolNameWarning = false;

  public protocols: any[] = [];

  public created() {
    axios.get("/controlpanel/protocols").then((res) => {
      this.protocols = res.data;
    }).catch((err) => {
      alert("Something went wrong");
    });
  }

  public orderChange(newProtocols: any) {
    const newOrders = newProtocols.map((download: any, index: any) => {
      return {id: download.id, order: index};
    });

    axios.patch("/controlpanel/protocols/order", {orders: newOrders}).then(() => {
      this.protocols = newProtocols;
    });

  }

  public validateNewDownload() {
    const fileInput = this.$refs.protocolFile as HTMLInputElement;
    this.protocolNameWarning = !this.protocolName;
    this.fileInputWarning = !fileInput.value;

    return !this.protocolNameWarning && !this.fileInputWarning;
  }

  public uploadDownload() {
    if (this.validateNewDownload()) {
      const fileInput = this.$refs.protocolFile as HTMLInputElement;
      const formData = new FormData();
      formData.append("name", this.protocolName);
      formData.append("protocolFile", fileInput.files[0]);

      axios.post("/controlpanel/protocols", formData).then((res) => {
        this.protocols.unshift(res.data);
        this.protocolName = "";
        fileInput.value = "";
        this.$modal.hide("upload-protocol");
      }).catch((err) => {
        alert("Something went wrong");
      });
    }
  }

  public editDownload(e: any) {
    this.editDownloadName = e.title;
    this.currentEditId = e.id;
    this.$modal.show("edit-protocol");
  }

  public validateEditDownload() {
    this.editProtocolNameWarning = !this.editDownloadName;

    return !this.editProtocolNameWarning;
  }

  public submitEdit() {
    const fileInput = this.$refs.editProtocolFile as HTMLInputElement;
    if (this.validateEditDownload()) {
      if (fileInput.value) {
        const formData = new FormData();
        formData.append("name", this.editDownloadName);
        formData.append("protocolFile", fileInput.files[0]);
        axios.patch("/controlpanel/protocols/" + this.currentEditId + "/file", formData)
          .then(() => {
            this.protocols.find(p => p.id == this.currentEditId).title = this.editDownloadName;
            fileInput.value = "";
            this.$modal.hide("edit-protocol");
          });
      } else {
        axios.patch("/controlpanel/protocols/" + this.currentEditId, {name: this.editDownloadName}).then(() => {
          this.protocols.find(p => p.id == this.currentEditId).title = this.editDownloadName;
          this.$modal.hide("edit-protocol");
        });
      }
    }
  }

  public deleteProtocol() {
    if (confirm(`Are you sure you want to delete this download? This action can not be undone.`)) {
      axios.delete("/controlpanel/protocols/" + this.currentEditId).then(() => {
        this.protocols.splice(this.protocols.findIndex(p => p.id == this.currentEditId), 1);
        this.$modal.hide("edit-protocol");
      });
    }
  }

  public resetEditValues() {
    this.editDownloadName = "";
    this.currentEditId = -1;
  }
}
