import { render, staticRenderFns } from "./DownloadsControlPanel.vue?vue&type=template&id=f6bdbe30&scoped=true&"
import script from "./DownloadsControlPanel.vue?vue&type=script&lang=ts&"
export * from "./DownloadsControlPanel.vue?vue&type=script&lang=ts&"
import style0 from "./DownloadsControlPanel.vue?vue&type=style&index=0&id=f6bdbe30&lang=scss&scoped=true&"
import style1 from "./DownloadsControlPanel.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6bdbe30",
  null
  
)

export default component.exports