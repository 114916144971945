









import {Component, Prop, Vue} from "vue-property-decorator";
import ControlPanelDownload from "./ControlPanelDownload.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    ControlPanelDownload,
    draggable,
  },
  props: ["downloads"],
})
export default class ControlPanelDownloadList extends Vue {
  @Prop()
  public downloads!: any[];

  get downloadsList() {
    return this.downloads;
  }

  set downloadsList(downloadsList) {
    this.$emit("changedOrder", downloadsList)
  }

  public editDownload(dl: any) {
    this.$emit("editDownload", dl);
  }
}
